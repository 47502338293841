import React from 'react'
import Layout from '../components/layout'
import Header from '../components/Header'
import Menu from '../components/Menu'
import BreadCrumb from '../components/Share/BreadCrumb'
import Footer from '../components/Footer'
import data from '../config/career.json'
import Title from '../components/Share/Title'
import Carousel from '../components/Carousel'
import Image from '../components/Lendis/Image'
import Button from '../components/Share/Button'

const Career = () => {
  const headerImage = process.env.CLOUDINARY_URL + '/f_auto,w_1920/' + data.headerImage

  return (
    <Layout>
      <Header isProductPage={true} productMetaData={data.metaData} />
      <Menu />
      <div
        className="big-background-image pb-8 bg-lendis-dark-white bg-no-repeat bg-top lg:pb-40 pt-40 px-3"
        style={{ backgroundImage: `url(${headerImage})` }}
      >
        <div className="container mx-auto text-white">
          <h1 className="font-bold lg:text-6xl px-8 text-3xl mb-5">{data.headerTitle}</h1>
          <p className="font-semibold lg:text-2xl mb-10 mb-5 px-8 text-xl ">{data.subHeader}</p>
        </div>
        <div className="bg-white container mx-auto lg:px-10 pb-10">
          <BreadCrumb absolute breadCrumbItems={data.breadcrumb} />
          <div>
            <div className="px-10 relative">
              <Title
                title="DISRUPTING THE OFFICE INDUSTRY"
                header="OUR MISSION"
                titleSize="text-3xl"
              />
              <div className="absolute hidden sm:block right-0 top-0 mt-5 w-1/2 mr-10 sm:w-1/3 lg:w-1/4 xl:mr-0">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://lendis-gmbh-jobs.personio.de/"
                >
                  <Button text="See Job Openings" green wFull className="mt-6 text-center" />
                </a>
              </div>
              <div className="sm:hidden w-full">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://lendis-gmbh-jobs.personio.de/"
                >
                  <Button text="See Job Openings" green wFull className="mt-6 text-center" />
                </a>
              </div>
            </div>

            <div className="bg-white px-10">
              <div className="mt-8 leading-loose  pb-8 lg:flex lg:items-center">
                <p>
                  Lendis is on a mission to take the hassle out of offices. To reach this goal we
                  offer a digital B2B rental service that empowers companies to subscribe to all
                  necessary office assets and services from one single source. To equip an office
                  with everything you need to work in a functional and comfortable surrounding -
                  incl. furniture, technical equipment, plants, coffee machines - is made easy,
                  quick and flexible. We are backed by renowned investors (HV Holtzbrinck Ventures,
                  DN Capital and Picus Capital) and have already won 500+ clients. Amongst our
                  clients are startups, SMEs but also Corporates like Toyota, Sparkasse or Deloitte.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="px-10 ">
              <Title
                title="AMBITIOUS, PASSIONATE AND SUPPORTIVE."
                header="OUR TEAM"
                titleSize="text-3xl"
                right
              />
            </div>
            <div className="bg-white px-10">
              <div className="md:bg-white md:z-10 md:relative ">
                <div className="pt-6 container mx-auto flex flex-wrap">
                  <div className="md:w-1/2 w-full">
                    <p className="md:pr-4">
                      We are a highly experienced and motivated team driven by our mission to
                      disrupt the office industry. Our international team has a diverse backround
                      with experiences in many different startups, enterprises, consulting firms and
                      investors. As we know that the success of Lendis is due to the dedication and
                      hard work of our team, we aim at making Lendis a great place to work. We don't
                      just want to attract the best and brightest people to join our journey, we
                      want them to stay and grow with us. We are pleased to be ranked as top company
                      by kununu.
                    </p>
                  </div>

                  <div className="md:w-1/2 w-full">
                    <Image src="website/normal_photo_1564047922" alt="chichi" noChange />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="px-10 ">
              <Title
                title="LENDIS IS SUPPORTED BY TOP-NOTCH INVESTORS"
                header="OUR INVESTORS"
                titleSize="text-3xl"
              />
            </div>
            <div className="bg-white px-10">
              <div className="md:bg-white md:z-10 md:relative ">
                <div className="pt-6 container mx-auto flex flex-wrap flex-col-reverse md:flex-row">
                  <div className="md:w-1/2 w-full">
                    <Image src="website/normal_photo_1564047436" alt="chichi" noChange />
                  </div>
                  <div className="md:w-1/2 w-full">
                    <p className="md:pl-4">
                      We are happy to have strong investors on board, who help us to fulfill our
                      vision of disrupting the office industry. HV Ventures, DN Capital and Picus
                      Capital are amongst Europe's leading venture capital investors and support us
                      with broad PropTech and FinTech expertise.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="px-10 ">
              <Title
                title="CENTRAL LOCATION IN BERLIN"
                header="OUR OFFICE"
                titleSize="text-3xl"
                right
              />
            </div>
            <div className="bg-white px-10">
              <div className="mt-8 leading-loose  pb-8 lg:flex lg:items-center">
                <p>
                  You'll find our office in the Oranienburger Straße, one of the top central
                  locations in Berlin. Light open spaces as well as quiet areas, a great team
                  kitchen, top office infrastructure, great coffee, refreshing mineral water and
                  free snacks provide an excellent work environment.
                </p>
              </div>
            </div>

            <div className="px-10 container mx-auto pt-12">
              <Carousel
                slideToShow={2}
                useCarouselSize={true}
                dots={false}
                arrows={true}
                carousels={[
                  {
                    html: (
                      <div className="relative mx-4">
                        <Image
                          className="w-96 mx-auto"
                          noChange
                          src="website/normal_photo_1564050199"
                          alt="t"
                        />
                      </div>
                    ),
                  },
                  {
                    html: (
                      <div className="relative mx-4">
                        <Image
                          className="w-96 mx-auto"
                          noChange
                          src="website/normal_photo_1564050201"
                          alt="t"
                        />
                      </div>
                    ),
                  },
                  {
                    html: (
                      <div className="relative mx-4">
                        <Image
                          className="w-96 mx-auto"
                          noChange
                          src="website/normal_photo_1564049300"
                          alt="t"
                        />
                      </div>
                    ),
                  },
                  {
                    html: (
                      <div className="relative mx-4">
                        <Image
                          className="w-96 mx-auto"
                          noChange
                          src="website/normal_photo_1564049302"
                          alt="t"
                        />
                      </div>
                    ),
                  },
                  {
                    html: (
                      <div className="relative mx-4">
                        <Image
                          className="w-96 mx-auto"
                          noChange
                          src="website/12normal_photo_1564049304"
                          alt="t"
                        />
                      </div>
                    ),
                  },
                  {
                    html: (
                      <div className="relative mx-4">
                        <Image
                          className="w-96 mx-auto"
                          noChange
                          src="website/23normal_photo_1564049302"
                          alt="t"
                        />
                      </div>
                    ),
                  },
                  {
                    html: (
                      <div className="relative mx-4">
                        <Image
                          className="w-96 mx-auto"
                          noChange
                          src="website/112normal_photo_1564049300"
                          alt="t"
                        />
                      </div>
                    ),
                  },
                ]}
              />
            </div>
          </div>

          <div>
            <div className="px-10 ">
              <Title title="OPEN POSITIONS" header="JOIN US NOW" titleSize="text-3xl" />
            </div>
            <div className="bg-white px-10">
              <div className="mt-8 leading-loose  pb-8 lg:flex lg:items-center">
                <p>
                  We look for a diverse range of roles in our team incl. Product, Tech, Sales,
                  Finance, People, Operations and BizDev. For more details please see the following
                  job descriptions.
                </p>
              </div>
              <div className="w-full mx-auto text-center">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://lendis-gmbh-jobs.personio.de/"
                >
                  <Button text="See Job Openings" green wFull className="mt-6 text-center" />
                </a>
              </div>
            </div>
          </div>

          <div>
            <div className="bg-white px-10">
              <div className="mt-8 leading-loose pb-8 lg:flex lg:items-center">
                <div className="mx-auto flex flex-wrap justify-center">
                  <div className="w-full">
                    <p className="font-semibold pt-10 py-6 text-3xl text-center text-lendis-main">
                      We are looking forward to hearing from you!
                    </p>
                  </div>
                  <div className="w-full flex flex-wrap justify-center">
                    <a
                      href="https://www.kununu.com/de/lendis"
                      title="KUNUNU"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <Image
                        alt="kununu"
                        src="website/62_Kununu-topcompany-Freigestellt_1_ibi05j.png"
                        fixSize="180"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
export default Career
